import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import NavbarWrapper from "../../../reusecore/Navbar";
import Drawer from "../../../reusecore/Drawer";
import Logo from "../../../reusecore/UIElements/Logo";
import HamburgMenu from "../../../reusecore/HamburgMenu";
import ScrollSpyMenu from "../../../reusecore/ScrollSpyMenu";
import { Container } from "./navbar.style";
import CopyrightSection from "../CopyrightsSection";
import Language from "../../../components/Language";
import LogoImage from "../../../assets/image/new/logoAHD.png";
import { useIntl } from "gatsby-plugin-intl";

import { DrawerContext } from "../../../contexts/DrawerContext";

const Navbar = ({ navbarStyle, logoStyle }) => {
  const { state, dispatch } = useContext(DrawerContext);
  const intl = useIntl();

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        menuItems {
          label
          path
          offset
        }
      }
    }
  `);

  var navbarData = Object.keys(intl.messages).reduce((acc, item) => {
    if (item.includes("navbarList")) {
      return [...acc, intl.messages[item]];
    }
    return acc;
  }, []);

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="/#"
          logoSrc={LogoImage}
          title="Agency"
          logoStyle={logoStyle}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <Language />
          <Drawer
            width="420px"
            placement="right"
            drawerHandler={<HamburgMenu barColor="black !important" />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <ScrollSpyMenu
              menuItems={Data.agencyJson.menuItems}
              drawerClose={true}
              offset={-100}
              navbarData={navbarData}
            />

            <CopyrightSection />
          </Drawer>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

Navbar.defaultProps = {
  // Default navbar style
  navbarStyle: {
    minHeight: "70px",
  },
  // Default logo size
  logoStyle: {
    width: "128px",
    height: "auto",
  },
};

export default Navbar;
